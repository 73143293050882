@import '~#/theme/main';

$description-bg-color: rgba($rgb-235, .15);

.episode {
  border: vw(3px) solid transparent;
  border-radius: vw(15px);
  display: inline-block;
  height: 92.5%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include browser($legacy-devices) {
    border-radius: 15px !important;
    width: vw(570px);
  }

  img {
    border: vw(3px) solid transparent;
  }

  &.focused img {
    border: vw(3px) solid var(--accent);
  }

  .container-image {
    height: 79%;
    width: 99%;

    .cover-image {
      border-radius: vw(12px);
      display: block;
    }
  }

  > p,
  .progress {
    position: absolute;
  }

  p {
    bottom: vw(20px);
    color: $light-grey;
    font-size: vw(20px);
    margin: 0;
    padding: 0 vw(10px);
  }

  .progress {
    background-color: rgba($light-grey, .5);
    border-top: vw(1px) solid $grey;
    bottom: vw(69px);
    height: vw(5px);
    left: 0;
    width: 100%;
    z-index: 1;

    > span {
      background-color: $button-border-grey;
      display: block;
      height: 100%;
    }
  }

  .title {
    bottom: vw(5.5px);
    color: $rgb-235;
    font-size: vw(30px);
    line-height: vw(32px);
    padding: vw(19.2px) 0;
    position: relative;
    width: 93.5%;

    @include browser($legacy-devices) {
      width: vw(531px);
    }
  }
}
